import React, { useEffect, useMemo } from 'react';
import {Container} from 'reactstrap';
import {Link, useParams} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCamera } from '@fortawesome/free-solid-svg-icons';
import '../Home/Home.css';
import './Seed.css';
import useSWR from "swr";

const Seed = () => {
    let { seedId } = useParams();
    const { data } = useSWR('/seeds.json', (...args) => fetch(...args).then(res => res.json()));
    const seed = useMemo(() => data?.find(record => record.id === Number(seedId)), [data, seedId]);

    useEffect(() => {
        document.title = `${seed?.strain} by ${seed?.bank}`;
    }, [seed]);

    return seed ? (
        <Container className="custom-container">
            <main className="main">
                <div className="row">
                    <h1 className="heading">{seed.strain}</h1>
                </div>
                <dl className="row">
                    <dt className="col-sm-3">Quantity</dt>
                    <dd className="col-sm-9">{seed.quantity}</dd>
                    <dt className="col-sm-3">Type</dt>
                    <dd className="col-sm-9">{seed.type}</dd>
                    <dt className="col-sm-3">Cycle</dt>
                    <dd className="col-sm-9">{seed.bloom}</dd>
                    <dt className="col-sm-3">Seed Bank/Breeder</dt>
                    <dd className="col-sm-9">{seed.bank}</dd>
                    {seed.resources && (
                        <>
                            <dt className="col-sm-3">Resources</dt>
                            <dd className="col-sm-9">
                                <ul className="nav flex-column">
                                    {seed.resources.map(({ id, label, url }, index) => (
                                        <li key={`${label}${index}`} className="nav-item">
                                            <a href={url} target="_blank" rel="noreferrer noopener">
                                                {label}<FontAwesomeIcon className="external-icon" icon={faExternalLinkAlt} />
                                            </a>
                                            <span className="divider">|</span>
                                            <a
                                                href={`/screenshots/${seedId}${id}.webp`}
                                                target="_blank" rel="noreferrer noopener"
                                                aria-label={`Screenshot of ${label} website`}
                                            >
                                                <FontAwesomeIcon icon={faCamera} />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </dd>
                        </>
                    )}
                    {seed.notes && (
                        <>
                            <dt className="col-sm-3">Notes</dt>
                            <dd className="col-sm-9">{seed.notes}</dd>
                        </>
                    )}
                </dl>
                <div className="row">
                    <Link to="/">Back</Link>
                </div>
            </main>
        </Container>
    ) : null;
};

export default Seed;
