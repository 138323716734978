import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import Home from './components/Home/Home';
import Seed from './components/Seed/Seed';

function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/seeds/:seedId">
                        <Seed />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
