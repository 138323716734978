import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import {useTable, useSortBy, useFilters} from 'react-table';
import Filter from "../Filter/Filter";
import SelectColumnFilter from "../SelectColumnFilter/SelectColumnFilter";
import './Table.css';

const TableWrapper = ({data, columns}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        defaultColumn: { Filter: SelectColumnFilter },
        initialState: {
            sortBy: [
                {
                    id: columns[0].accessor,
                    desc: false,
                },
            ],
        }
    }, useFilters, useSortBy);

    return (
        <Table bordered striped responsive {...getTableProps()}>
            <thead>
            {// Loop over the header rows
                headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {// Loop over the headers in each row
                            headerGroup.headers.map((column) => (
                                // Apply the header cell props
                                <th {...column.getHeaderProps()}>
                                    <div className={`headingContainer ${column.disableFilters === true ? 'no-filter' : ''}`} {...column.getSortByToggleProps()}>
                                        {column.render('Header')}
                                        {column.canSort && (
                                            <div className="sortContainer">
                                                <FontAwesomeIcon icon={faSortUp} size="xs" className={`${column.isSorted && !column.isSortedDesc ? '' : 'inactive-sort'} sort-up`} />
                                                <FontAwesomeIcon icon={faSortDown} size="xs" className={`${column.isSorted && column.isSortedDesc ? '' : 'inactive-sort'} sort-down`} />
                                            </div>
                                        )}
                                    </div>
                                    <Filter column={column} />
                                </th>
                            ))}
                    </tr>
                ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
                rows.map(row => {
                    // Prepare the row for display
                    prepareRow(row)
                    return (
                        // Apply the row props
                        <tr {...row.getRowProps()}>
                            {// Loop over the rows cells
                                row.cells.map(cell => {
                                    // Apply the cell props
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {// Render the cell contents
                                                cell.render('Cell')}
                                        </td>
                                    )
                                })}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    );
};

export default TableWrapper;