import React from 'react';
import { CustomInput } from "reactstrap";

const SelectColumnFilter = ({
                                column: { filterValue, setFilter, preFilteredRows, id },
                            }) => {
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    return (
        <CustomInput
            id="custom-select"
            type="select"
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.sort().map(option => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </CustomInput>
    )
};

export default SelectColumnFilter;