import React from 'react';
import {Input} from 'reactstrap';

const TextColumnFilter = ({
                              column: {
                                  filterValue,
                                  setFilter,
                                  preFilteredRows: {length},
                              },
                          }) => {
    return (
        <Input
            value={filterValue || ""}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`search (${length}) ...`}
        />
    );
};

export default TextColumnFilter;