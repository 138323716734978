import React, { useEffect } from 'react';
import useSWR from "swr";
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import Table from '../Table/Table';
import TextColumnFilter from '../TextColumnFilter/TextColumnFilter';
import './Home.css';

const tableColumns = [
    {
        Header: 'Strain',
        accessor: 'strain',
        Cell: (cellProps) => {
            const {id, strain} = cellProps.row.original;
            return <Link to={`/seeds/${id}`}>{strain}</Link>;
        },
        Filter: TextColumnFilter,
        filter: 'text',
    },
    {
        Header: 'Quantity',
        accessor: 'quantity',
        disableFilters: true,
    },
    {
        Header: 'Type',
        accessor: 'type',
    },
    {
        Header: 'Cycle',
        accessor: 'bloom',
    },
    {
        Header: 'Bank/Breeder',
        accessor: 'bank',
    },
];

const Home = () => {
    useEffect(() => {
        document.title = 'MySeeds';
    });

    const { data } = useSWR('/seeds.json', (...args) => fetch(...args).then(res => res.json()));
    const filteredData = data?.filter(({ quantity }) => {
        if (typeof quantity === 'number' && quantity > 0) {
            return true;
        }

        return typeof quantity === 'string';
    });

    return data ?  (
        <Container className="custom-container">
            <main className="main">
                <Table columns={tableColumns} data={filteredData || []}/>
            </main>
        </Container>
    ): null;
};

export default Home;
